import Link from "next/link";

interface FooterLinkProps {
  href: string;
  children: JSX.Element | string;
  className?: string;
}

const FooterLink = ({ href, children, className }: FooterLinkProps) => (
  <li className={className}>
    <Link href={href} className="hover:underline" data-gtm-id="footer_navigation">
      {children}
    </Link>
  </li>
);

export default FooterLink;
