import Image from "next/legacy/image";
import Link from "next/link";
import { useState } from "react";
import ChevronRight from "@/public/icons/chevron-right.svg";
import {
  SecondaryNavigationNode,
  TertiaryNavigationNode,
} from "@/services/prepr/queries/getLayoutContent";
import { getUrlFromNavigationNode } from "@/utilities/getUrlFromNavigationNode";
import Button from "@/components/shared/Button";
import { SubMenu } from "../NavbarMobile";
import { useDialogForms } from "../../useDialogForms";

interface Props {
  menuItems: SecondaryNavigationNode[] | TertiaryNavigationNode[];
  menuTitle: string;
  setActiveSubMenu: React.Dispatch<React.SetStateAction<SubMenu | string>>;
  closeMenu: () => void;
  href?: string;
  dataGTMId?: string;
  adviesGesprekButton?: boolean;
}

const Menu: React.FC<Props> = ({
  href,
  menuItems,
  menuTitle,
  setActiveSubMenu,
  closeMenu,
  dataGTMId,
  adviesGesprekButton = true,
}) => {
  const [activeSubSubMenu, setActiveSubSubMenu] = useState<SubMenu | string>("nothing");
  const { openDialog } = useDialogForms();
  return (
    <div className="bg-secondary-aubergine absolute inset-x-0 top-0 z-20 h-full">
      <button
        className="text-menu flex w-full items-center px-8 pb-4 pt-8 font-bold"
        type="button"
        onClick={() => setActiveSubMenu("nothing")}
      >
        <Image className="rotate-180" src={ChevronRight} alt="Chevron icon" width="8"></Image>
        <span className="ml-6">{menuTitle}</span>
      </button>
      <ul>
        {href && (
          <li className="mx-8 border-b-2 border-white/20">
            <Link
              href={href || "/404"}
              data-testid={menuTitle}
              data-gtm-id={dataGTMId || "second_navigation"}
              className="text-menu-sm flex w-full items-center justify-between py-6 font-bold"
              onClick={closeMenu}
            >
              Overzicht
            </Link>
          </li>
        )}
        {menuItems.map((item) => (
          <li key={item.title} className="mx-8 border-b-2 border-white/20">
            {item?.__typename === "SecondaryNavigationNode" && !!item.nodes?.length ? (
              <>
                <button
                  data-gtm-id={dataGTMId || "second_navigation"}
                  className="text-menu-sm flex w-full items-center justify-between py-6 font-bold"
                  onClick={() => setActiveSubSubMenu(item.title ?? "")}
                >
                  {item.title}
                  <Image src={ChevronRight} alt="chevron right" width="8"></Image>
                </button>
                {activeSubSubMenu === item.title && (
                  <Menu
                    href={getUrlFromNavigationNode(item)}
                    menuItems={item.nodes}
                    menuTitle={`${menuTitle} ${item.title}`}
                    setActiveSubMenu={setActiveSubSubMenu}
                    closeMenu={closeMenu}
                    dataGTMId="third_navigation"
                  />
                )}
              </>
            ) : (
              <Link
                href={getUrlFromNavigationNode(item)}
                className="text-menu-sm flex w-full items-center justify-between py-6 font-bold"
                onClick={closeMenu}
                data-gtm-id={dataGTMId || "second_navigation"}
              >
                {item.title}
              </Link>
            )}
          </li>
        ))}
        {adviesGesprekButton && (
          <li className="flex w-full justify-center">
            <Button
              className="mt-4"
              variant="tertiary"
              onClick={openDialog("lead-requestAppointment", "Maak een afspraak (lead)")}
            >
              Plan een gratis advies gesprek
            </Button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Menu;
