import SpinnerSection from "./Spinner/SpinnerSection";

interface Props {
  text?: string;
  visible: boolean;
}

const Spinner: React.FC<Props> = ({ text, visible }) => (
  <div
    className={`pointer-events-none absolute inset-0 flex items-center justify-center transition-opacity ${
      visible ? "opacity-100" : "opacity-0"
    }`}
  >
    <div className="border-secondary-burgundy relative flex size-60 items-center justify-center overflow-hidden text-3xl">
      <span className="rounded-full px-6 py-3 backdrop-blur">
        <span className="animate-pulse">{text || "Loading..."}</span>
      </span>
      <SpinnerSection className="animate-spin-1 border-t-secondary-grapefruit" />
      <SpinnerSection className="animate-spin-2 border-l-primary-emerald" />
      <SpinnerSection className="animate-spin-3 border-r-primary-mint" />
    </div>
  </div>
);

export default Spinner;
