import Link from "next/link";
import { accountLinks } from "../accountLinks";

interface Props {
  closeMenu: () => void;
}

const MainMenuLi = ({ children }: { children: React.ReactNode }) => (
  <li className="border-secondary-burgundy mx-8 border-b-2">{children}</li>
);

const MainMenuLink = ({
  name,
  href,
  onClick,
  target,
}: {
  name: string;
  href: string;
  onClick: () => void;
  target?: "_self" | "_blank";
}) => (
  <MainMenuLi>
    <Link
      href={href}
      className="text-menu-sm flex w-full items-center justify-between py-6 font-bold"
      onClick={onClick}
      target={target}
      rel={(target === "_blank" && "noopener noreferrer") || undefined}
    >
      {name}
    </Link>
  </MainMenuLi>
);

const MainMenu: React.FC<Props> = ({ closeMenu }) => {
  return (
    <div className="bg-primary-black relative h-full">
      <ul>
        {accountLinks.map(({ name, href }) => (
          <MainMenuLink key={name} name={name} href={href} onClick={closeMenu} target="_blank" />
        ))}
      </ul>
    </div>
  );
};

export default MainMenu;
