interface Props {
  className: string;
}

const SpinnerSection: React.FC<Props> = ({ className }) => (
  <div
    className={`${className} absolute size-full rounded-full border-8 border-transparent mix-blend-multiply`}
  ></div>
);

export default SpinnerSection;
