import Link from "next/link";
import React from "react";

type Variant = "dark" | "primary" | "secondary" | "white";

interface TextLinkProps {
  type?: "button" | "link" | "externalLink";
  variant?: Variant;
  className?: string;
  href?: string | null;
  onClick?: () => void;
  children?: React.ReactNode;
  target?: "_blank" | "_self" | "_parent" | "_top";
}

const TextLink: React.FC<TextLinkProps> = ({
  type = "link",
  variant = "primary",
  className,
  onClick,
  href,
  children,
  target,
}) => {
  const classes =
    `transition-1 after:transition-1 before:transition-1 after:transition-1 justify-left relative flex items-center pb-4 font-sans text-button-sm font-semibold before:absolute
  before:bottom-0 before:h-[2px] before:w-full before:bg-gradient-to-r before:content-[''] after:absolute 
  after:bottom-0 after:h-[2px] after:w-full after:bg-gradient-to-r after:content-[''] tablet:text-button
  ${styles[variant]} ${className ?? ""}`.trim();

  if (type === "button") {
    return (
      <button className={classes} onClick={onClick}>
        {children}
      </button>
    );
  }
  if (type === "link") {
    return (
      <Link
        href={href || "/404"}
        className={classes}
        target={target}
        rel={(target === "_blank" && "noopener noreferrer") || undefined}
        data-gtm-id="link_click"
      >
        {children}
      </Link>
    );
  }
  if (type === "externalLink" && href) {
    return (
      <a
        className={classes}
        target={target}
        href={href.startsWith("http") ? href : `//${href}`}
        rel={(target === "_blank" && "noopener noreferrer") || undefined}
        data-gtm-id="link_click"
      >
        {children}
      </a>
    );
  }
  return null;
};

const styles: Record<Variant, string> = {
  primary: `text-primary-airforce hover:text-primary-mint 
    before:from-primary-mint before:to-[rgba(0,0,0,0)] before:hover:opacity-100
    after:from-primary-airforce after:to-[rgba(0,0,0,0)] after:hover:opacity-0`,
  secondary: `text-secondary-burgundy hover:text-secondary-aubergine 
      before:from-secondary-aubergine before:to-[rgba(0,0,0,0)] before:hover:opacity-100
      after:from-secondary-burgundy after:to-[rgba(0,0,0,0)] after:hover:opacity-0`,
  white: `text-white
        before:hover:from-secondary-aubergine before:to-[rgba(0,0,0,0)] before:hover:opacity-100
      `,
  dark: `text-white hover:text-secondary-grapefruit 
    before:hidden
    after:from-secondary-grapefruit after:to-[rgba(0,0,0,0)]`,
};

export default TextLink;
