import Link from "next/link";
import { TertiaryNavigationNode } from "@/services/prepr/queries/getLayoutContent";
import { getUrlFromNavigationNode } from "@/utilities/getUrlFromNavigationNode";

const SubMenu: React.FC<{
  nodes: TertiaryNavigationNode[];
  show: boolean;
}> = ({ nodes, show }) => {
  return (
    <ul
      data-testid="tertiary-node"
      className={`bg-secondary-aubergine shadow-submenu absolute top-full transition-transform ${
        show ? "visible translate-y-0" : "invisible -translate-y-full"
      }`}
    >
      {nodes.map((item) => (
        <li key={item.title} className="group w-80">
          <Link
            href={getUrlFromNavigationNode(item)}
            prefetch={false}
            className="text-menu-sm flex w-full justify-between px-6 font-bold hover:bg-white/20"
            data-gtm-id="third_navigation"
          >
            <span className="w-full border-b border-white/20 py-6 group-last:border-0">
              {item.title}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SubMenu;
