import gql from "graphql-tag";
import { print } from "graphql";
import { runQuery } from "@/services/prepr/sdk";
import { Path } from "@/utilities/types";
import { GetLayoutContentQuery } from "@/services/prepr/queries/getLayoutContent.generated";
import { NavTargetFragment } from "@/services/prepr/fragments/NavTargetFragment";
import { ImagePresetsFragment } from "../fragments/ImagePresetsFragment";
import { QueryParamsFragment } from "../fragments/QueryParamsFragment";

export type PrimaryNavigationNode = Path<
  GetLayoutContentQuery,
  ["PrimaryNavigationNodes", "items", number]
>;
export type Footer = Path<GetLayoutContentQuery, ["Footer"]>;
export type SecondaryNavigationNode = Path<PrimaryNavigationNode, ["nodes", number]>;
export type TertiaryNavigationNode = Path<SecondaryNavigationNode, ["nodes", number]>;
export type DialogForm = Path<GetLayoutContentQuery, ["DialogForms", "items", number]>;
export type WhitepaperDialogProps = Path<GetLayoutContentQuery, ["WhitepaperDialog"]> & {
  slug: string | undefined;
  title: string | undefined;
  body: string | undefined;
};

const query = gql`
  query GetLayoutContent {
    PrimaryNavigationNodes {
      items {
        title
        navbar_item
        target {
          ...NavTargetFragment
        }
        query_params {
          ...QueryParamsFragment
        }
        nodes {
          __typename
          title
          target {
            ...NavTargetFragment
            ... on ExternalPage {
              url
            }
          }
          query_params {
            ...QueryParamsFragment
          }
          nodes {
            __typename
            title
            target {
              ...NavTargetFragment
            }
            query_params {
              ...QueryParamsFragment
            }
          }
        }
      }
    }
    Footer {
      footer_links_column_1 {
        name
        bold
        link {
          ... on StaticPage {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on ExternalPage {
            _slug
          }
        }
      }
      footer_links_column_2 {
        name
        bold
        link {
          ... on StaticPage {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on ExternalPage {
            _slug
          }
        }
      }
      footer_links_column_3 {
        name
        bold
        link {
          ... on StaticPage {
            _slug
          }
          ... on DynamicPage {
            _slug
          }
          ... on ExternalPage {
            _slug
          }
        }
      }
      newsletter {
        __typename
        title
        text
        button_text
        privacy_statement_text
      }
    }
    DialogForms(limit: null) {
      items {
        name
        title
        description
        submitbuttontext
        form_type
        salesforce_entity
        campaigntag
        confirmationtitle
        confirmationtext
        confirmation_image {
          ...ImagePresetsFragment
        }
      }
    }
    WhitepaperDialog {
      subtitle
      buttontext
      image {
        ...ImagePresetsFragment
      }
      subscribetext
      endtext
      downloadscreentitle
      downloadscreenbody
    }
  }
  ${print(NavTargetFragment)}
  ${print(QueryParamsFragment)}
  ${print(ImagePresetsFragment)}
`;

// todo: confirmationtitle, confirmationtext should be snake_case, consistent with all other fields

export const getLayoutContent = async () => {
  const response = await runQuery<GetLayoutContentQuery>(query);
  return response;
};
