import { MouseEventHandler, useEffect, useRef, useState } from "react";
import Image from "next/legacy/image";
import { any } from "ramda";

interface Props {
  children: JSX.Element | string | JSX.Element[];
  isOpen: boolean;
  onClose?: () => void;
  hasWhiteCloseIcon?: boolean;
  className?: string;
  showCloseButton?: boolean;
}
const focusablesQuery =
  'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

const Dialog: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  hasWhiteCloseIcon,
  className,
  showCloseButton = true,
}) => {
  const dialog = useRef<HTMLDivElement>(null);
  const stopPropagation: MouseEventHandler = (e) => e.stopPropagation();
  const handleTabPresses = () => {
    const focusables = dialog.current?.querySelectorAll(focusablesQuery) as
      | NodeListOf<HTMLElement>
      | undefined;
    if (focusables) {
      const firstTabbable = focusables[0];
      const lastTabbable = focusables[focusables.length - 1];
      const firstTab = function (event: globalThis.KeyboardEvent) {
        if (event.key === "Tab") {
          const alreadyCaptured = !!(
            document.activeElement &&
            any((f: HTMLElement) => document.activeElement === f, Array.from(focusables))
          );
          if (!alreadyCaptured) {
            event.preventDefault();
            firstTabbable.focus();
          }
          document.removeEventListener("keydown", firstTab);
        }
      };
      const tabListener = function (event: globalThis.KeyboardEvent) {
        if (!event.shiftKey && event.key === "Tab") {
          event.preventDefault();
          firstTabbable.focus();
        }
      };
      const shiftTabListener = function (event: globalThis.KeyboardEvent) {
        if (event.shiftKey && event.key === "Tab") {
          event.preventDefault();
          lastTabbable.focus();
        }
      };
      if (isOpen) {
        document.addEventListener("keydown", firstTab);
        lastTabbable.addEventListener("keydown", tabListener);
        firstTabbable.addEventListener("keydown", shiftTabListener);
      } else {
        lastTabbable.removeEventListener("keydown", tabListener);
        firstTabbable.removeEventListener("keydown", shiftTabListener);
        document.removeEventListener("keydown", firstTab);
      }
    }
  };
  const setBodyOverflow = () => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };
  type TransitionDurationMs = 100 | 150 | 200 | 300 | 500 | 1000;
  const transitionDurationMs: TransitionDurationMs = 300;
  const [isOpaque, setOpaque] = useState(false);
  const [isHidden, setHidden] = useState(true);
  useEffect(() => {
    handleTabPresses();
    setBodyOverflow();
    if (!isOpen) {
      setOpaque(false);
      setTimeout(() => {
        setHidden(true);
      }, transitionDurationMs);
    } else {
      setHidden(false);
      setTimeout(() => {
        setOpaque(true);
      }, 20); // hack to make sure fade in transition works
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const hiddenClass = isHidden ? "hidden" : "block";
  const overlayOpenClass = isOpaque ? "bg-primary-black/80" : "bg-primary-black/0";
  const dialogOpenClass = isOpaque ? "opacity-100 -translate-y-1/2" : "opacity-0 -translate-y-2/3";
  const transitionDurationClass = `transition duration-${transitionDurationMs}`;
  const dialogClasses = `
    fixed top-1/2 left-1/2 z-50 max-h-full w-[calc(100%-2rem)] -translate-y-1/2 -translate-x-1/2 
    overflow-y-auto rounded-2xl bg-white tablet:bottom-auto tablet:w-[90%] desktop-s:w-[872px] ${transitionDurationClass} ${dialogOpenClass} ${
    className ?? ""
  }`;
  return (
    <div
      className={`bg-primary-black/80 fixed inset-0 z-40 ${transitionDurationClass} ${overlayOpenClass} ${hiddenClass}`}
      onClick={onClose}
    >
      <div ref={dialog} role="dialog" onClick={stopPropagation} className={dialogClasses}>
        <div className="tablet:top-10 tablet:right-10 absolute right-4 top-4 z-10 flex justify-end">
          {showCloseButton ? (
            <button
              aria-label="Close"
              onClick={onClose}
              className={`tablet:size-6 relative flex size-5 ${
                hasWhiteCloseIcon ? "drop-shadow-md" : ""
              }`}
            >
              <Image
                src="/icons/close-dialog.svg"
                layout="fill"
                alt="Close icon"
                className={hasWhiteCloseIcon ? "invert" : ""}
              />
            </button>
          ) : (
            <></>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Dialog;
